
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const router = useRouter();
    const i18n = useI18n();
    const store = useStore();

    i18n.locale.value = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "ko";

    const countries = [
      {
        code: "en",
        flag: "/media/flags/united-states.svg",
        name: "English",
      },
      {
        code: "ko",
        flag: "/media/flags/south-korea.svg",
        name: "Korean",
      },
    ];

    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.replace({ name: "login" }));
    };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      i18n.locale.value = lang;
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries.filter((r) => r.code === i18n.locale.value)[0];
    });

    return {
      signOut,
      setLang,
      currentLanguage,
      currentLangugeLocale,
      countries,
    };
  },
});
