const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Menu.Dashboard",
        route: "/dashboard",
        svgIcon: "/media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "Menu.AlManage",
    pages: [
      {
        heading: "Menu.AlAddHistory",
        route: "/al/deposits/history",
        svgIcon: "/media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
      },
      {
        heading: "Menu.AlHistory",
        route: "/al/history",
        svgIcon: "/media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
      },
      {
        heading: "Menu.Point",
        route: "/al/point",
        svgIcon: "/media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
      },
    ],
  },
  {
    heading: "Menu.AgentManage",
    pages: [
      {
        heading: "Menu.AgentList",
        route: "/agents",
        svgIcon: "/media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
      },
      {
        heading: "Menu.SettlementManage",
        route: "/agents/settlement",
        svgIcon: "/media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
      },
      {
        heading: "Menu.LoginHistoryIpManage",
        route: "/agents/login-access",
        svgIcon: "/media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
      },
    ],
  },
  {
    heading: "Menu.MemberManage",
    pages: [
      {
        heading: "Menu.MemberList",
        route: "/members",
        svgIcon: "/media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
      },
      {
        heading: "Menu.MemberBetHistory",
        route: "/game/bet-history",
        svgIcon: "/media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
      },
    ],
  },
  {
    heading: "Menu.GameManage",
    pages: [
      {
        heading: "Menu.GameChipSetting",
        route: "/settings/bet-chips",
        svgIcon: "/media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
      },
      {
        heading: "Menu.BetLimitSetting",
        route: "/settings/bet-limit",
        svgIcon: "/media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
      },
      {
        heading: "Menu.OtherSetting",
        route: "/settings/others",
        svgIcon: "/media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
      },
      {
        heading: "Menu.ResultManage",
        route: "/games/result",
        svgIcon: "/media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
      },
    ],
  },
  {
    heading: "API",
    pages: [
      {
        sectionTitle: "Menu.ApiManual",
        svgIcon: "/media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        sub: [
          {
            heading: "ApiManual.Gamelist",
            route: "/api/document/live/gamelist",
          },
          {
            heading: "ApiManual.Auth",
            route: "/api/document/live/launch",
          },
          {
            heading: "ApiManual.Balance",
            route: "/api/document/live/balance",
          },
          {
            heading: "ApiManual.Debit",
            route: "/api/document/live/debit",
          },
          {
            heading: "ApiManual.Credit",
            route: "/api/document/live/credit",
          },
          {
            heading: "ApiManual.Rollback",
            route: "/api/document/live/rollback",
          },
        ],
      },
      {
        heading: "Menu.Success",
        route: "/api/logs/success",
        svgIcon: "/media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
      },
      {
        heading: "Menu.Error",
        route: "/api/logs/error",
        svgIcon: "/media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
      },
    ],
  },
];

export default DocMenuConfig;
